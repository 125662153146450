import React from 'react';
import styled from 'styled-components';
import styles from '../../../utility/styles';
import Text from '../../shared/Text';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`

// padding is 20 here to match the padding of the previous versions
const MyRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid ${styles.colors.darkGray};
    border-bottom: ${({ isLast }) => isLast ? `1px solid ${styles.colors.darkGray}` : 'none'};
    width: 100%;
`

const BarWrapper = styled.div`
    position: relative;
    width: 200px;
    height: 8px;
    border-radius: 4px;
    background-color: ${styles.colors.darkGray};
`

const Bar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ pct }) => pct}%;
    bottom: 0;
    background-color: ${styles.colors.white};
    border-radius: 4px;
`

const NameRow = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`

/**
 * 
 * @param {{
 *   locations: {country: string, state:string, pct: number}[]
 * }} props
 * @returns 
 */
export default function Locations({ locations = [] }) {
    return (
        <Wrapper>
            {locations.length < 1 && <Text.Caption>No Locations</Text.Caption>}
            {locations.map(({ country, state, pct, title }, i, arr) => (
                <MyRow key={`${country}-${state}`} isLast={i === arr.length - 1}>
                    <NameRow>
                        <Text.Caption color={styles.colors.blue} style={{ marginRight: '4px' }}><Text.Caption variant='bold' as='span'>{title}</Text.Caption> {pct}%</Text.Caption>
                    </NameRow>
                    <BarWrapper>
                        <Bar {...{ pct }} />
                    </BarWrapper>
                </MyRow>
            ))}
        </Wrapper>
    )
}