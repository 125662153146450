import React, { useState } from 'react'
import { Container, Row, Col, Form, Image } from 'react-bootstrap'
import validator from 'validator'
import styled from 'styled-components'
import ScreenWrapper from '../shared/ScreenWrapper'
import {
	FlashWrapper
} from '../shared/Password'
import FeatureTableRow from '../shared/FeatureTableRow'
import Text from '../shared/Text'
import Button from '../shared/Button'
import StoreButton from '../shared/StoreButton'
import styles from '../../utility/styles'
import { apple_watch } from '../../utility/icons'
import Input from '../shared/Input'

const StyledContainer = styled(Container)`
	margin-top: 72px;
	margin-bottom: 72px;
`

const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 32px 24px;
	border: 1px solid ${styles.colors.darkGray};
	border-radius: 4px;
	margin: 80px 80px 24px 80px;
	@media only screen and ${styles.breakpoints.mobileL} {
		margin: 80px 16px;
	}
	@media only screen and (max-width: 991px) {
		margin: 16px 0;
	}
`

const CenteredDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const CheckWrapper = styled.div`
	position: absolute;
	right: 8px;
	top: 0;
	bottom: 12px;
	width: 24px;
	display: flex;
	opacity: ${({ isValid }) => (isValid ? 1 : 0)};
	transition: opacity 0.5s;
	align-items: center;
`

const Wrapper = styled.div`
	align-items: center;
	margin: 0 80px 69px 80px;
	overflow: hidden;
	height: 224px;
	padding-top: 24px;
	@media only screen and (max-width: 991px) {
		margin: 0 0 24px 0;
	}
`

const GiftWrapper = styled.div`
	display: flex;
	background-color: ${styles.colors.blue};
	align-items: center;
	padding: 32px 0 32px 305px;
	position: relative;
	border-radius: 4px;
	margin-bottom: 24px;
	@media only screen and ${styles.breakpoints.tablet} {
		padding: 12px 0 12px 305px;
	}
	@media only screen and (max-width: 510px) {
		padding: 12px 24px;
	}
`

const GiftCol = styled.div`
	display: flex;
	flex-direction: column;
	z-index: 100;
`

const Watch = styled(Image)`
	width: 400px;
    height: auto;
    position: absolute;
    left: -60px;
    bottom: -30px;
	@media only screen and ${styles.breakpoints.tablet} {
		width: 350px;
		bottom: 0;
	}
	@media only screen and (max-width: 510px) {
		display: none;
	}
`

const StyledForm = styled(Form)`
	position: relative;
	width: 60%;
	@media only screen and ${styles.breakpoints.mobileL} {
		width: 100%;
	}
`

const onSubmit = e => e.preventDefault()

export default function Subscribe({ pricing = {} }) {
	const [email, setEmail] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState('')

	const onChange = ({ target }) => setEmail(target.value)

	const onSubmitEmail = async () => {
		if (isSubmitting) return
		try {
			if (!validator.isEmail(email)) throw 'invalid email'
			setIsSubmitting(true)
			setError('')
			const response = await fetch('/v2/password/magic_link', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email: email }),
			})

			if (response.status !== 200) throw 'unable to send'
			const parsed_response = await response.json()
			setSuccess(parsed_response.request_processed)
			setIsSubmitting(false)
		} catch (error) {
			setError(
				'Hmm, something went wrong. If this keeps happening, please give us a shout at support@tezlabapp.com so we can help.'
			)
			setSuccess(false)
			setIsSubmitting(false)
		}
	}

	return (
		<ScreenWrapper>
			<StyledContainer>
				<Text.Heading variant='bold' style={{ textAlign: 'center' }}>
					Subscribe
				</Text.Heading>
				<Row>
					<Col>
						<InputWrapper>
							{success && (
								<svg
									width='73'
									height='56'
									viewBox='0 0 73 56'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
									style={{ marginBottom: '12px' }}
								>
									<mask
										id='mask0_2377_51953'
										style={{ maskType: 'alpha' }}
										maskUnits='userSpaceOnUse'
										x='0'
										y='3'
										width='70'
										height='53'
									>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M45.8338 11.8788C45.8338 8.73919 46.7811 5.82086 48.4056 3.39404H0.015625V56.0001H69.5914V24.5797C67.1646 26.2041 64.2462 27.1515 61.1065 27.1515C52.6716 27.1515 45.8338 20.3137 45.8338 11.8788Z'
											fill='#10ABFF'
										/>
									</mask>
									<g mask='url(#mask0_2377_51953)'>
										<path
											d='M4.25781 16.8618C4.25781 12.4435 7.83953 8.86182 12.2578 8.86182H57.3487C61.767 8.86182 65.3487 12.4435 65.3487 16.8618V41.5891C65.3487 46.0074 61.767 49.5891 57.3487 49.5891H12.2578C7.83953 49.5891 4.25781 46.0074 4.25781 41.5891V16.8618Z'
											stroke='#10ABFF'
											strokeWidth='3'
											strokeLinejoin='round'
										/>
										<path
											d='M6.80469 47.0907L26.0771 28.5232M63.6532 47.0907L43.5316 28.5232'
											stroke='#10ABFF'
											strokeWidth='3'
											strokeLinecap='round'
										/>
										<path
											d='M6.38281 11.0303L30.8017 32.6943C33.0835 34.7187 36.5211 34.7088 38.7912 32.6714L61.9586 11.8788'
											stroke='#10ABFF'
											strokeWidth='3'
											strokeLinecap='round'
										/>
									</g>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M61.1054 0C54.5449 0 49.2266 5.31832 49.2266 11.8788C49.2266 18.4393 54.5449 23.7576 61.1054 23.7576C67.6658 23.7576 72.9841 18.4393 72.9841 11.8788C72.9841 5.31832 67.6658 0 61.1054 0Z'
										fill='#10ABFF'
									/>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M64.4383 12.0339L62.422 11.5626C62.275 11.5287 62.1848 11.3833 62.2194 11.2391L63.875 4.43186C63.9207 4.24163 63.765 4.10107 63.6044 4.10107C63.529 4.10107 63.4524 4.13137 63.3944 4.20286L57.6371 11.2948C57.5135 11.4487 57.5938 11.6777 57.7878 11.7238L59.8041 12.1939C59.9511 12.229 60.0413 12.3732 60.0067 12.5174L58.3524 19.3246C58.3055 19.5149 58.4611 19.6566 58.6218 19.6566C58.6971 19.6566 58.7737 19.6263 58.8318 19.5548L64.5891 12.4617C64.7138 12.3078 64.6335 12.0788 64.4383 12.0339Z'
										fill='white'
									/>
								</svg>
							)}
							<Text.Heading
								type='h2'
								mobileL='h3'
								variant='bold'
								style={{
									marginBottom: '12px',
									textAlign: 'center',
								}}
							>
								{success
									? 'Check Your Email'
									: "Let's get you into your account..."}
							</Text.Heading>
							<Text.Heading
								type='h5'
								mobileL='caption'
								style={{
									marginBottom: '12px',
									textAlign: 'center',
								}}
							>
								{success
									? `If an account exists under ${email}, a link to access your account will arrive momentarily. Please check your junk mail if you do not receive the link.`
									: 'What is the email address on your TezLab account?'}
							</Text.Heading>
							{!success && (
								<StyledForm
									{...{ onSubmit }}
								>
									<Input
										value={email}
										{...{ onChange }}
										label='TezLab Account Email'
										id='email'
										type='email'
										style={{ marginBottom: '12px' }}
									/>
									<CheckWrapper
										isValid={validator.isEmail(email)}
									>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<circle
												cx='12'
												cy='12'
												r='9'
												fill='white'
											/>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM8.29289 13.7071C7.90237 13.3166 7.90237 12.6834 8.29289 12.2929C8.68342 11.9024 9.31658 11.9024 9.70711 12.2929L11 13.5858L15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289C17.0976 9.68342 17.0976 10.3166 16.7071 10.7071L11.7071 15.7071C11.3166 16.0976 10.6834 16.0976 10.2929 15.7071L8.29289 13.7071Z'
												fill='#8BC16A'
											/>
										</svg>
									</CheckWrapper>
								</StyledForm>
							)}
							{!!error && (
								<FlashWrapper variant='error'>
									<Text.Caption color={styles.colors.error}>
										{error}
									</Text.Caption>
								</FlashWrapper>
							)}
							{success ? (
								<Button
									variant='secondary'
									loading={isSubmitting}
									onClick={onSubmitEmail}
									style={{ marginTop: '12px' }}
								>
									Retry
								</Button>
							) : (
								<Button
									onClick={onSubmitEmail}
									loading={isSubmitting}
									disabled={!validator.isEmail(email)}
								>
									Send Me a Link
								</Button>
							)}
						</InputWrapper>
					</Col>
				</Row>
				<Row>
					<Col>
						<Wrapper>
							<GiftWrapper>
								<Watch src={apple_watch} />
								<GiftCol>
									<Text.Heading
										variant='bold'
										type='h2'
										style={{ marginBottom: '4px' }}
										tablet='h4'
									>
										Gift a Pro Membership
									</Text.Heading>
									<Text.Heading
										type='h5'
										style={{ marginBottom: '24px' }}
										tablet='caption'
									>
										Gift a prepaid subscription to TezLab
										Pro.
									</Text.Heading>
									<Button
										href='/gift'
										as='a'
										style={{
											backgroundColor:
												styles.colors.white,
											flex: 0,
											alignSelf: 'flex-start',
										}}
									>
										<Text.Caption
											color={styles.colors.blue}
											style={{ marginRight: '12px' }}
										>
											Buy Now
										</Text.Caption>
										<svg
											width='16'
											height='16'
											viewBox='0 0 16 16'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M10.7813 8.66667L8.39464 11.06L9.33463 12L13.3346 8L9.33463 4L8.39464 4.94L10.7813 7.33333H2.66797V8.66667H10.7813Z'
												fill={styles.colors.blue}
											/>
										</svg>
									</Button>
								</GiftCol>
							</GiftWrapper>
						</Wrapper>
					</Col>
				</Row>
			</StyledContainer>
			<Text.Heading
				variant='bold'
				type='h2'
				mobileL='h3'
				style={{ margin: '0 24px 4px 24px', textAlign: 'center' }}
				id='pricing'
			>
				Plans and Pricing
			</Text.Heading>
			<Text.Heading
				type='h5'
				mobileL='caption'
				style={{ margin: '0 24px 56px 24px', textAlign: 'center' }}
			>
				All first time subscriptions start with a{' '}
				<span style={{ fontWeight: 800 }}>
					full access 14-day free trial of TezLab Pro.
				</span>
			</Text.Heading>
			<Container
				fluid
				style={{ marginBottom: '40px', maxWidth: '880px' }}
			>
				<FeatureTableRow.Heading />
				<FeatureTableRow.Body
					freeType='free'
					text='Create and participate in groups'
				/>
				<FeatureTableRow.Body
					freeType='free'
					text='Customize your dashboard with your favorite widgets'
				/>
				<FeatureTableRow.Body
					freeType='free'
					text='Plan better trips with our supercharger map'
				/>
				<FeatureTableRow.Body
					freeType='free'
					text='Plant trees and become carbon neutral'
				/>
				<FeatureTableRow.Body
					freeType='free'
					text='Current drives live data'
				/>
				<FeatureTableRow.Body
					freeType='free'
					text='Current charge power and range forcasts'
				/>
				<FeatureTableRow.Body freeType='free' text='Car controls' />
				<FeatureTableRow.Body
					freeType='free'
					text='Carbon impact report for charges'
				/>
				<FeatureTableRow.Body
					freeType='free'
					text='Power mix reports'
				/>
				<FeatureTableRow.Body
					freeType='7-day'
					text='Unlimited tracking of drives and charges'
				/>
				<FeatureTableRow.Body
					freeType='7-day'
					text='Efficiency comparison to others in your area'
				/>
				<FeatureTableRow.Body
					freeType='limited'
					text='Comprehensive view of how you charge and use your battery'
				/>
				<FeatureTableRow.Body
					freeType='none'
					text='Pro Usage Report with Detailed Idle Analysis'
				/>
				<FeatureTableRow.Body freeType='none' text='Road Trips' />
				<FeatureTableRow.Body freeType='none' text='Map Range Rings' />
				<FeatureTableRow.Body
					freeType='none'
					text='Delayed/Scheduled Climate Start'
				/>
				<FeatureTableRow.Body
					freeType='none'
					text='Location Based Alerts (i.e. Plug In Reminders)'
				/>
				<FeatureTableRow.Body
					freeType='none'
					text='Track multiple vehicles'
				/>
				<FeatureTableRow.Body
					freeType='none'
					text='Enhanced polling for improved data detail'
				/>
				<FeatureTableRow.Body
					freeType='none'
					text='TezLab Apple Watch App (for Tesla)'
				/>
				<FeatureTableRow.Body
					freeType='none'
					text='Data Export to CSV'
				/>
				<FeatureTableRow.Footer {...pricing} />
			</Container>
			<Text.Heading
				type='h2'
				variant='bold'
				style={{ textAlign: 'center', margin: '0 56px 16px 56px' }}
				mobileL='h3'
			>
				Download today and start exploring the EV lifestyle.
			</Text.Heading>
			<CenteredDiv style={{ marginBottom: '56px' }}>
				<StoreButton type='apple' />
				<StoreButton type='android' />
			</CenteredDiv>
		</ScreenWrapper>
	)
}
