import React from 'react';
import styled from 'styled-components';
import styles from '../../../utility/styles';
import Text from '../../shared/Text';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`

const MyRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-top: 1px solid ${styles.colors.darkGray};
    border-bottom: ${({ isLast }) => isLast ? `1px solid ${styles.colors.darkGray}` : 'none'};
    width: 100%;
`

const VersionRow = styled.div`
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: space-around;
`

const TextRow = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`

const VersionStringWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`

/**
 * 
 * @param {{
 *   versions: {version: string, pct: number}[],
 *   currentVersion: string
 * }} props
 * @returns 
 */
export default function PreviousVersions({ versions = [], currentVersion }) {
    return (
        <Wrapper>
            {versions.length < 1 && <Text.Caption>No Previous Versions</Text.Caption>}
            {versions.map(({ version, pct }, i, arr) => (
                <MyRow key={version} isLast={i === arr.length - 1}>
                    <VersionRow>
                        <VersionStringWrapper>
                            <Text.Caption variant='bold' color={styles.colors.blue} style={{ border: `1px solid ${styles.colors.blue}`, borderRadius: '24px', padding: '4px 8px' }}>{version}</Text.Caption>
                        </VersionStringWrapper>
                        <VersionStringWrapper>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M16.67 13L13.09 16.59L14.5 18L20.5 12L14.5 6L13.09 7.41L16.67 11H4.5V13H16.67Z" fill={styles.colors.white} />
                            </svg>
                        </VersionStringWrapper>
                        <VersionStringWrapper>
                            <Text.Caption color={styles.colors.white}>{currentVersion}</Text.Caption>
                        </VersionStringWrapper>
                    </VersionRow>
                    <TextRow>
                        <Text.Caption>{pct}%</Text.Caption>
                    </TextRow>
                </MyRow>
            ))}
        </Wrapper>
    )
}