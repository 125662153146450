import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { VictoryChart, VictoryStack, VictoryBar, VictoryAxis, VictoryLabel, VictoryTooltip } from 'victory';
import moment from 'moment';
import styled from 'styled-components';
import styles from '../../../utility/styles';

const formatTicks = (tick, index, ticks) => {
    const formatted = moment(tick).format('MM/DD')
    if (index === 0) return `introduced 
    ${formatted}`
    if (index === ticks.length - 1) {
        const today = moment().startOf('day')
        const last = moment(tick).startOf('day')
        if (today.diff(last, 'day') < 1) return formatted
        return `Phased Out 
        ${formatted}`
    }
    if (ticks.length > 25) return index % 2 === 0 ? formatted : '' // show every other tick if there are more than 30
    return formatted
}

const returnBarWidth = (len, width) => {
    if (!len || !width) return 56
    if (len < 3) len = 3
    return width / len * 0.8
}

const returnModelName = model => {
    if (/^(S|X|3|Y)$/.test(model)) return `Model ${model}`
    return model
}

const Wrapper = styled.div`
    width: 100%;
    height: 350px;
    @media only screen and ${styles.breakpoints.tablet} {
		& > .VictoryContainer {
			user-select: auto !important;
        	pointer-events: auto !important;
        	touch-action: auto !important;
		}
	}
`

/**
 * @typedef Data
 * @property {Date} date
 * @property {number} pct
 * @property {{model:string,pct:number}[]} models
 */

/**
 * 
 * @param {{
 *   rollout: Data[],
 *   colors: {[model]:string}
 * }} props
 * @returns 
 */
export default function RolloutChart({ rollout, colors }) {
    const ref = useRef()
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
    // dataSet is an array of objects with the following shape:
    // {
    //   model: string,
    //   data: { x: Date, y: number }[]
    // }
    const dataSet = useMemo(() => {
        // get all models
        const models = rollout.map(({ models }) => models.map(({ model_name }) => model_name)).flat().reduce((acc, model) => {
            if (!acc.includes(model) && model !== 'all') acc.push(model)
            return acc
        }, [])
        // for every date, get the percentage of each model
        const data = models.map(model => {
            const arr = rollout.map(({ date, models }) => {
                const obj = models.find(({ model_name }) => model_name === model)
                const label = `${returnModelName(model)} ${(obj?.pct ?? 0).toFixed(1)}%`
                return { x: date, y: obj?.pct ?? 0, label }
            })
            return { model, data: arr }
        })
        // return the array of models with their respective percentages by date
        return data
    }, [rollout])

    const colorScale = useMemo(() => {
        const arr = []
        dataSet.forEach(({ model }) => {
            const key = returnModelName(model)
            const color = colors[key] || styles.colors.white
            arr.push(color)
        })
        return arr
    }, [colors, dataSet])

    const barWidth = useMemo(() => returnBarWidth(rollout.length, dimensions.width), [rollout.length, dimensions.width])
    const fontSize = useCallback(() => {
        if (dimensions.width < 360) return '8px'
        if (dimensions.width < 768) return '12px'
        return '16px'
    }, [dimensions.width])

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: ref.current.offsetWidth,
                height: ref.current.offsetHeight
            })
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <Wrapper {...{ ref }}>
            <VictoryChart {...dimensions} domainPadding={{ x: barWidth / 2 }} padding={{ bottom: 75, top: 50, right: 50, left: 50 }}>
                <VictoryAxis
                    dependentAxis
                    tickFormat={(tick) => `${tick}%`}
                    style={{
                        tickLabels: {
                            fill: styles.colors.white,
                        },
                        axis: {
                            stroke: styles.colors.transparent,
                        },
                        grid: {
                            stroke: styles.colors.white,
                        }
                    }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: {
                            fill: styles.colors.white,
                            fontSize,
                        },
                        axis: {
                            stroke: styles.colors.lightGray,
                        }
                    }}
                    tickLabelComponent={<VictoryLabel angle={-45} textAnchor='end' />}
                    tickFormat={(...args) => formatTicks(...args)}
                />
                <VictoryStack
                    {...{ colorScale }}
                >
                    {dataSet.map(datum => (
                        <VictoryBar
                            key={datum.model}
                            data={datum.data}
                            {...{ barWidth }}
                            labelComponent={<VictoryTooltip />}
                        />
                    ))}
                </VictoryStack>
            </VictoryChart>
        </Wrapper>
    )
}